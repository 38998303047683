<template>
  <v-row justify="center">
    <v-dialog v-model="shower" scrollable persistent max-width="400px">
      <v-card>
        <v-card-title>{{ $t("myinboxPage.settingHeader.setting") }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-overlay :value="loadprogress" absolute :color="color.BG" dark>
            <v-progress-circular indeterminate size="64" :color="color.theme"></v-progress-circular>
          </v-overlay>
          <div v-if="resolutionScreen <= 500">
            <v-list v-if="$t('default') === 'en'">
              <v-list-item v-for="(items, i) in feature" :key="i">
                <v-list-item-action>
                  <v-switch
                    inset
                    :color="color.theme"
                    :value="items"
                    v-model="selected"
                  ></v-switch>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title v-text="items.text"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
            <v-list v-else>
              <v-list-item v-for="(items, i) in feature" :key="i">
                <v-list-item-action>
                  <v-switch
                    inset
                    :color="color.theme"
                    :value="items"
                    v-model="selected"
                  ></v-switch>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title v-text="items.text"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>            
          </div>
          <div v-else>
            <v-list v-if="$t('default') === 'en'">
              <v-layout>
                <v-flex>
                  <v-list-item v-for="(items, i) in feature" :key="i">
                    <v-list-item-action>
                      <v-switch
                        inset
                        :color="color.theme"
                        :value="items"
                        v-model="selected"
                      ></v-switch>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title v-text="items.text"></v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-flex>
              </v-layout>
            </v-list>
            <v-list v-else>
              <v-layout>
                <v-flex>
                  <v-list-item v-for="(items, i) in feature" :key="i">
                    <v-list-item-action>
                      <v-switch
                        inset
                        :color="color.theme"
                        :value="items"
                        v-model="selected"
                      ></v-switch>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title v-text="items.text"></v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-flex>
              </v-layout>
            </v-list>
          </div>
          <v-card-text></v-card-text>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red" outlined @click="$emit('closedialog')">{{ $t("admin.close") }}</v-btn>
          <v-btn
            :color="color.theme"
            :dark="color.darkTheme"
            @click="settingfeature(),$emit('reload')"
          >{{ $t("myinboxPage.settingHeader.submit") }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import { mapGetters, mapState } from "vuex";
import gbfGenerate from "@/globalFunctions/generateAuthorize";
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'

const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000
});

export default {
  props: ['show', 'functionsetting'],
  data() {
    return {
      featureStatus: {},
      selected: [],
      loadprogress: true,
    }
  },
  watch: {
    feature() {
      this.selected = this.feature.filter(f => f.status)
      console.log("selected", this.selected);
    }
  },
  computed: {
    ...mapState(["username", "authorize", "account_active", "color"]),
    ...mapGetters([
      "dataUsername",
      "dataAuthorize",
      "dataAccountActive",
      "dataAccesstoken",
      "dataBusinessProfile",
      "dataCitizenProfile",
      "dataDepartmentAccessId",
      "dataAccountId",
      "dataStorageUsage",
      "dataStorageMax",
      "dataCheckPackage",
    ]),
    resolutionScreen() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 220;
        case "sm":
          return 400;
        case "md":
          return 500;
        case "lg":
          return 600;
        case "xl":
          return 800;
      }
    },    
    shower: {
      get() {
        if(this.show === true){
          this.getfeature();
        }
        return this.show
      },
      set (value) {
        if (!value) {
          this.selected = [];
        }
      }
    },
    feature() {
      return [
        {
          text: this.$t("tablefile.filenameonly"),
          key: 'filename',
          status: this.featureStatus["filename"] === 'Y'
        },
        {
          text: this.$t("tablefile.docno"),
          key: 'document_no',
          status: this.featureStatus["document_no"] === 'Y'
        },
        {
          text: this.$t("tablefile.docdate"),
          key: 'document_date',
          status: this.featureStatus["document_date"] === 'Y'
        },
        {
          text: this.$t("tablefile.doctype"),
          key: 'document_type_code',
          status: this.featureStatus["document_type_code"] === 'Y'
        },
        {
          text: this.$t("tablefile.receivername"),
          key: 'buyer_name',
          status: this.featureStatus["buyer_name"] === 'Y'
        },
        {
          text: this.$t("tablefile.outboxdate"),
          key: 'cre_dtm',
          status: this.featureStatus["cre_dtm"] === 'Y'
        },
        {
          text: this.$t("tablefile.status_onechat"),
          key: 'status_onechat',
          status: this.featureStatus["status_onechat"] === 'Y'
        },
        {
          text: this.$t("tablefile.po_number"),
          key: 'po_number',
          status: this.featureStatus["PO"] === 'Y'
        },
      ]
    }
  },
  methods: {
    async getfeature() {
      this.selected = [];
      var payload = {
        account_id : this.dataAccountId,
        business_id: this.dataAccountActive.business_info.business_id
      }
      let auth = await gbfGenerate.generateToken();
      this.axios
        .post(process.env.VUE_APP_SERVICE_ADMIN + "/api/get_default_inbox", payload, {
          headers: { Authorization: auth.code },
        })
        .then((response) => {
          if (response.data.status === "OK") {
            console.log('response', response);
            this.featureStatus = response.data.default_outbox;
            this.loadprogress = false;
          } else {
            Toast.fire({
              icon: "error",
              title: response.data.errorCode + ': ' + response.data.errorMessage,
            })
          }
        }).catch(error => {
          console.log(error);
          Toast.fire({
            icon: "error",
            title: this.$t("toast.cannotconnectonebox")
          })
        })
    },
    async settingfeature() {
      let setfeature = {};
      for (let i = 0; i < this.feature.length; i++) {
        if (this.selected.length === 0) {
          setfeature[this.feature[i].key] = "N";
          console.log('ไม่มีรายการที่เลือก');
        } else {
          for (let j = 0; j < this.selected.length; j++) {
            if (this.feature[i].key === this.selected[j].key) {
              setfeature[this.feature[i].key] = "Y";
              console.log("yes");
              break;
            } else {
              setfeature[this.feature[i].key] = "N";
              console.log("no");
            }
          }
        }
        console.log('setfeature', setfeature);
      }
      this.selected = [];
      var payload = {
        account_id: this.dataAccountId,
        business_id: this.dataAccountActive.business_info.business_id,
        data_type: "outbox",
        filename: setfeature.filename,
        document_no: setfeature.document_no,
        document_date: setfeature.document_date,
        document_type_code: setfeature.document_type_code,
        buyer_name: setfeature.buyer_name,
        cre_dtm: setfeature.cre_dtm,
        status_onechat: setfeature.status_onechat,
        seller_taxid: "N",
        seller_branch: "N",
        seller_name: "N",
        buyer_taxid: "N",
        buyer_branch: "N",
        document_type: "N",
        PO: setfeature.PO
      }
      console.log('payload', payload);
      let auth = await gbfGenerate.generateToken();
      this.axios
        .post(process.env.VUE_APP_SERVICE_ADMIN + "/api/set_default_inbox", payload, {
          headers: { Authorization: auth.code },
        })
        .then(response => {
          if (response.data.status === "OK") {
            Toast.fire({
              icon: "success",
              title: this.$t("admintoast.settingsuccess")
            });
            this.$emit('closedialog');
          } else {
            Toast.fire({
              icon: "error",
              title: response.data.errorCode + ': ' + response.data.errorMessage,
            });
            this.$emit('closedialog');
          }
        }).catch(error => {
          console.log("error", error);  
          Toast.fire({
            icon: "error",
            title: error
          });
        })
    },
  },
}
</script>
